<template>
  <section>
    <div class="v-container">
      <page-legal-info :active-tab="activeTab" />
    </div>
  </section>
</template>

<script setup lang="ts">
const route = useRoute()

let activeTab = -1

onBeforeMount(() => {
  switch (route.name) {
    case 'legal-agreement': {
      activeTab = 0
      break
    }
    case 'legal-pay-rules': {
      activeTab = 1
      break
    }
    case 'legal-personal-data': {
      activeTab = 2
      break
    }
    case 'legal-points': {
      activeTab = 3
      break
    }
    default: {
      activeTab = Number.parseInt(
        route.name?.toString()?.replace('legal-additional-', '') ?? '0'
      )
    }
  }
})
</script>
