<i18n>
ru:
  error: >-
    Ошибка: Содержимое страницы не найдено. Страница может быть отключена в
    админ-панели. Пожалуйста, обратитесь к администратору.
  offer: Публичная оферта
  paymentRules: Условия оплаты
  personalData: Политика обработки персональных данных
  pointsPage: Условия бонусной системы
ua:
  error: >-
    Помилка: Зміст сторінки не знайдено. Сторінка може бути вимкнена в
    адмін-панелі. Будь ласка, зверніться до адміністратора.
  offer: Публічна оферта
  paymentRules: Умови оплати
  personalData: Політика обробки персональних даних
  pointsPage: Умови бонусної системи
us:
  error: >-
    Page content not found.  The page may be disabled in the admin panel. Please
    contact your administrator.
  offer: Public offer
  paymentRules: Payment terms
  personalData: Personal data processing policy
  pointsPage: Bonus system terms and conditions
</i18n>

<template>
  <div class="v-legal-page">
    <div class="v-mb-sm">
      <arora-chips
        with-links
        :chips="tabSettingsArray"
        v-model:selected="tabSelectedId"
      />
    </div>
    <page-legal-offer
      v-if="
        tabSelectedId === 0 &&
        appConfig.StaticTexts.offer &&
        appConfig.StaticTexts.offer.visibility
      "
      :content="appConfig.StaticTexts.offer.content ?? ''"
      :title="appConfig.StaticTexts.offer.title ?? ''"
    />
    <page-legal-payment-rules
      v-else-if="
        tabSelectedId === 1 &&
        appConfig.StaticTexts.paymentRules &&
        appConfig.StaticTexts.paymentRules.visibility
      "
      :content="appConfig.StaticTexts.paymentRules.content ?? ''"
      :requisites="appConfig.StaticTexts.requisites ?? ([] as LegalInfoRequisite[])"
      :title="appConfig.StaticTexts.paymentRules.title ?? ''"
    />
    <page-legal-personal-data
      v-else-if="
        tabSelectedId === 2 &&
        appConfig.StaticTexts.personalData &&
        appConfig.StaticTexts.personalData.visibility
      "
      :content="appConfig.StaticTexts.personalData.content ?? ''"
      :title="appConfig.StaticTexts.personalData.title ?? ''"
    />
    <page-legal-points
      v-else-if="
        tabSelectedId === 3 &&
        appConfig.StaticTexts.pointsPage &&
        appConfig.StaticTexts.pointsPage.visibility
      "
      :content="appConfig.StaticTexts.pointsPage.content ?? ''"
      :title="appConfig.StaticTexts.pointsPage.title ?? ''"
    />
    <template
      v-else-if="(tabSelectedId ?? -1) > 3"
      v-for="[
        index,
        additionalDocument
      ] in appConfig.StaticTexts.additional.entries()"
    >
      <div
        v-if="tabSelectedId === index + 4"
        :key="index"
        class="v-pb-xs"
        v-html="additionalDocument.content"
      />
    </template>
    <h1
      v-else
      v-html="translate('legalInfo.error')"
      class="v-error-color"
    />
  </div>
</template>

<script setup lang="ts">
import type { TabsSettings } from '~types/common'
import type { LegalInfoRequisite } from '~types/preloadRequests'

const { activeTab } = defineProps<{
  activeTab: number
}>()

const appConfig = useAppConfig()
const { translate } = useI18nSanitized()

const tabSelectedId = ref<number | undefined>(undefined)
const tabSettingsArray = ref<TabsSettings<number>[]>([] as TabsSettings<number>[])
tabSelectedId.value = activeTab

onBeforeMount(async () => {
  if (appConfig.StaticTexts.offer && appConfig.StaticTexts.offer.visibility)
    tabSettingsArray.value.push({
      ID: 0,
      Link: appConfig.VueSettingsPreRun.Links.AgreementLink,
      Name: appConfig.StaticTexts.offer.title ?? translate('legalInfo.offer')
    })

  if (
    appConfig.StaticTexts.paymentRules &&
    appConfig.StaticTexts.paymentRules.visibility
  )
    tabSettingsArray.value.push({
      ID: 1,
      Link: appConfig.VueSettingsPreRun.Links.PayRulesLink,
      Name:
        appConfig.StaticTexts.paymentRules.title ??
        translate('legalInfo.paymentRules')
    })

  if (
    appConfig.StaticTexts.personalData &&
    appConfig.StaticTexts.personalData.visibility
  )
    tabSettingsArray.value.push({
      ID: 2,
      Link: appConfig.VueSettingsPreRun.Links.PersonalDataLink,
      Name:
        appConfig.StaticTexts.personalData.title ??
        translate('legalInfo.personalData')
    })

  if (
    appConfig.StaticTexts.pointsPage &&
    appConfig.StaticTexts.pointsPage.visibility
  )
    tabSettingsArray.value.push({
      ID: 3,
      Link: appConfig.VueSettingsPreRun.Links.PointsLink,
      Name:
        appConfig.StaticTexts.pointsPage.title ?? translate('legalInfo.pointsPage')
    })

  if (
    appConfig.StaticTexts.additional &&
    appConfig.StaticTexts.additional.length > 0
  ) {
    for (let index = 0; index < appConfig.StaticTexts.additional.length; index++) {
      tabSettingsArray.value.push({
        ID: index + 4,
        Link: appConfig.StaticTexts.additional[index].link,
        Name: appConfig.StaticTexts.additional[index].title
      })
    }
  }
})
</script>

<style lang="scss">
.v-legal-page {
  h1 {
    font-size: 1.6rem;
  }
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-size: 1.3rem;
  }

  ul {
    list-style: disc;
    padding-left: 40px;
    margin-bottom: 0.5rem;
  }

  ol {
    list-style: disc;
    padding-left: 40px;
    margin-bottom: 0.5rem;
  }
}
</style>
